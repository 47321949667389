import React, {useEffect, useState} from 'react';
import api from "../../api";
import {Button, Checkbox, Col, Form, Input, Row} from "antd";
import {useForm} from "antd/es/form/Form";
import TextArea from "antd/lib/input/TextArea";

export default function SendUpdates() {

    const [updates, setUpdates] = useState([]);
    const [selectedUpdates, setSelectedUpdates] = useState([]);
    const [exampleText, setExampleText] = useState("");
    const [exampleSubject, setExampleSubject] = useState("");
    const [form] = useForm();

    useEffect(() => {
        api.getAllUpdates().then((updates) => {
            setUpdates(updates.filter(update => !update.sent));
        });
    }, []);

    function sendUpdates() {
        form.validateFields().then(values => {
            const updateIds = Object.keys(values).filter(key => values[key])
                .filter(key => key !== "description")
                .filter(key => key !== "subject");

            api.sendUpdates(updateIds, values.description, values.subject).then(() => {
                alert("Updates zijn verstuurd, no way back now.");
            })
        })
    }

    const updateExample = (changedValues, allValues) => {

        setSelectedUpdates(updates.filter(update => {
            return allValues[update.id]
        }));


        const newExampleText = form.getFieldValue("description");
        if (newExampleText) {
            setExampleText(newExampleText.replace("[name]", "Stef"))
        }
        const exampleSubject = form.getFieldValue("subject");
        if (exampleSubject) {
            setExampleSubject(exampleSubject.replace("[name]", "Stef"))
        }
    }

    return (
        <div>
            <h1>Updates versturen</h1>
            <p>Iedere update is gekoppeld aan een aanvraag. Aanvragers krijgen alleen een emails als er een update bij zit van een van de aangevraagde gewassen</p>
            <p>Selecteer hieronder de gewassen waar je de updates van wilt versturen.</p>
            <Row gutter={[24,24]}>
                <Col span={12}>
                    <Form form={form} layout={"vertical"} onValuesChange={updateExample}>
                        {updates.map(update => (
                            <Form.Item key={update.id} name={update.id} valuePropName="checked">
                                <Checkbox>
                                    {update.planting.crop.name}: {update.title}
                                </Checkbox>

                            </Form.Item>
                        ))}
                        <Form.Item name={"subject"} label={"Onderwerp"}>
                            <Input placeholder={"Onderwerp"}/>
                        </Form.Item>
                        <Form.Item name={"description"} label={"Email bericht"}>
                            <div>
                                <TextArea placeholder={"Bericht"} style={{width: "100%"}}/>
                            </div>
                        </Form.Item>

                        <Button type={"primary"} onClick={sendUpdates}>Verstuur updates</Button>

                    </Form>
                </Col>
                <Col span={12}>

                    <h1>Voorbeeld email</h1>
                    <p>Onderwerp: {exampleSubject}</p>
                    <div
                        style={{padding: '30px', border: 'solid 1px silver', background: 'white', textAlign: "center"}}>

                        <h1>{exampleSubject}</h1>
                        <pre style={{fontFamily: 'Arial'}}>{exampleText}</pre>
                        {selectedUpdates.map((update) => {
                            return (
                                <>
                                    <h2>{update.planting.crop.name}: {update.title}</h2>
                                    <img src={update.image} alt={update.title}
                                         style={{maxWidth: '150px', maxHeight: '150px', margin: '20px 0'}}/>
                                    <br/>
                                    <p>{update.description}</p>
                                </>
                            )
                        })}

                    </div>

                </Col>
            </Row>


        </div>
    )
        ;
}