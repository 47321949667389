import React, {useEffect, useState} from 'react';
import api from "../../api";
import {Button, Card, Checkbox, Col, Image, Row} from "antd";
import dayjs from "dayjs";
import './style.css';
import {useNavigate} from "react-router-dom";

export default function Updates() {

    const [updates, setUpdates] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        api.getAllUpdates().then(setUpdates);
    }, []);

    const deleteUpdate = (update) => {
        api.deleteUpdate(update.planting_id, update.id).then(() => {
            setUpdates(updates.filter(u => u.id !== update.id));
        });
    }

    return (
        <div className={"text-center"}>
            <h1>Updates</h1>
            {api.isLoggedIn() && (
                <Button type={"primary"} size={"large"} onClick={() => navigate(`/updates/send`)}>Verstuur updates</Button>
            )}
            <hr/>
            <Row gutter={12}>
                {updates.map(update => (
                    <Col key={update.id} md={6} xs={24}>
                        <Card>
                            <h1>{dayjs(update.date).format("dddd D MMMM")}</h1>
                            <h2>{update.title}</h2>
                            <p>{update.description}</p>
                            <p><b>{}</b></p>

                            <div className={"update-image-box"}>
                                <Image src={update.image} width={"100%"}></Image>
                            </div>
                            {api.isLoggedIn() && (
                                <>
                                    <br/>
                                    <Checkbox checked={update.sent}>Verzonden</Checkbox>
                                    <br/><br/>
                                    <Button size={"small"} danger
                                            onClick={() => deleteUpdate(update)}>Verwijderen</Button>
                                </>
                            )}
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
}