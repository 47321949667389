import {useEffect, useState} from "react";
import React from "react";
import api from "../../api";
import {Button, Col, Form, Input, Modal, notification, Row} from "antd";
import './style.css'
import dayjs from "dayjs";
import {useForm} from "antd/es/form/Form";
import {useParams} from "react-router-dom";


export default function Request() {

    const [notificationApi, contextHolder] = notification.useNotification();
    const {company_id} = useParams();
    const [crops, setCrops] = useState([]);
    const [cart, setCart] = useState([])
    const [cartModalVisible, setCartModalVisible] = useState(false)
    const [form] = useForm();

    //
    useEffect(() => {
        api.companyCrops(company_id).then(setCrops);
    }, [])

    function addAll() {
        const newCart = [...crops];
        setCart(newCart);
        notificationApi.info({
            message: `Alle groenten toegevoegd! 🥕`,
            description: 'Alle groenten zijn toegevoegd, onderaan de pagina kan je je groentenmandje bekijken.',
            placement: 'top',
            duration: 3
        })
        setCartModalVisible(true)
    }

    function addToCart(crop) {
        // Check if the crop is not already in the cart
        if (cart.find((c) => c.id === crop.id)) {
            notificationApi.warning({
                message: `Deze groente zit al in je mandje ;-)`,
                description: 'Onderaan de pagina kan je je groentenmandje bekijken.',
                placement: 'top',
                duration: 3
            });
            return;
        }
        notificationApi.info({
            message: `Groente toegevoegd! 🥕`,
            description: 'De groente is toegevoegd, onderaan de pagina kan je je groentenmandje bekijken.',
            placement: 'top',
            duration: 3
        });
        setCart([...cart, crop]);
    }

    function sendRequest() {
        const values = form.getFieldsValue();
        if (cart.length === 0) {
            notificationApi.error({
                message: `Je mandje is leeg!`,
                description: 'Voeg eerst groenten toe aan je mandje.',
                placement: 'top',
                duration: 3
            });
            return;
        }
        if(!values.name || !values.email || !values.phone) {
            notificationApi.error({
                message: `Vul alle velden in!`,
                description: 'Vul je naam, e-mailadres en telefoonnummer in.',
                placement: 'top',
                duration: 3
            });
            return;
        }
        const crop_ids = cart.map((p) => p.id)

        api.addReservation(values.name, values.email, values.phone, crop_ids, company_id).then(() => {
            notificationApi.success({
                message: `Bedankt! 🥕`,
                description: 'Je aanvraag is verstuurd, we sturen je een bericht als er groenten voor je beschikbaar zijn.',
                placement: 'top',
                duration: 10
            });
            setCart([]);
            setCartModalVisible(false);
        })
    }

    return (
        <div>
            {contextHolder}
            <Modal footer={null} open={cartModalVisible} onCancel={() => setCartModalVisible(false)}>
                <h1>Je groentenmandje</h1>
                <div className={"cart"}>
                    {cart.map((crop) => {
                        return (
                            <Row key={crop.id + 'cart'}>
                                <Col span={4}>
                                    <div className={"img-wrapper"}>
                                        <img src={crop.image} alt={crop.name}/>
                                    </div>
                                </Col>
                                <Col span={15}>
                                    <b>{crop.name}</b>
                                </Col>
                                <Col span={5} style={{textAlign: "right"}}>
                                    <Button size={"small"} onClick={() => {
                                        setCart(cart.filter((c) => c.id !== crop.id))
                                    }}>Verwijder</Button>
                                </Col>
                            </Row>
                        )
                    })}
                </div>
                <Row>
                    <Col span={24}>
                        <h1>Jouw gegevens</h1>
                        <Form form={form} layout={"vertical"}>
                            <Form.Item name={"name"} label={"Naam"} labelCol={24}>
                                <Input/>
                            </Form.Item>
                            <Form.Item name={"email"} label={"E-mailadres"} labelCol={24}>
                                <Input/>
                            </Form.Item>
                            <Form.Item name={"phone"} label={"Telefoonnummer"} labelCol={24}>
                                <Input/>
                            </Form.Item>
                        </Form>
                        <Button onClick={sendRequest} type={"primary"}>Versturen</Button>
                    </Col>
                </Row>
            </Modal>
            <Row>
                <Col span={24}>
                    <div className={"intro"}>
                        <h1>Hallo en welkom in de Moestuin! 👋</h1>
                        <p>
                            <br/>Zoals jullie weten, staan we het liefst met onze voeten in de aarde van onze moestuin.
                            <br/>Dit jaar verwachten we een mooie oogst en we dachten: waarom niet delen met
                            jullie!
                            <br/>Dus hier kunnen jullie laten weten welke groenten jullie van ons willen hebben.
                            <br/>
                            <br/>Het is simpel, recht uit de grond, zonder poespas. Gewoon verse groenten, voor
                            jullie, van ons land.
                            <br/>Laat ons weten wat jullie willen en dan zetten we het voor jullie aan
                            de kant.
                            <br/>
                            <br/>Houd er rekening mee dat dit alles onder voorbehoud is van eigen gebruik en het
                            weer.
                            <br/>We delen graag wat we kunnen missen en wat de natuur ons toelaat te geven.
                            <br/><br/>We sturen je een bericht als er groenten voor je beschikbaar zijn.
                        </p>

                        <h2>Kun je niet kiezen?</h2>
                        <p>
                            <Button type={"primary"} onClick={addAll} size={"large"}>Voeg alles toe</Button>
                        </p>
                    </div>
                </Col>
            </Row>
            <Row gutter={16}>
                {crops.filter(crop => crop.plantings.length > 0).map((crop) => {
                    return (
                        <Col key={crop.id} xs={24} md={4}>
                            <div className={"planting-box"}>
                                <h1>{crop.name}</h1>
                                <h2>{crop.variety}</h2>
                                <div className="img-wrapper">
                                    <img src={crop.image}/>
                                </div>
                                <div className={"dates"}>
                                    <h3>Verwacht:</h3>
                                    {crop.plantings.map((planting) => {
                                        return (<div>{dayjs(planting.harvest_at).format('MMMM YYYY')}</div>)
                                    })}
                                </div>
                                <div className="buttons">
                                    <Button onClick={() => addToCart(crop)} type={"primary"}>Voeg toe</Button>
                                </div>
                            </div>
                        </Col>
                    )
                })}
            </Row>

            <div className={"footer"}>
                <Row>
                    <Col span={12}>
                        <h1>{cart.length} producten in groentenmandje</h1>
                    </Col>
                    <Col span={12} className={"cart-button-wrapper"}>
                        <Button type={"primary"} onClick={() => setCartModalVisible(true)}>
                            Mandje bekijken
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    )
}