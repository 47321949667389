
export const PLANTING_STATUS = {
    PLANNED: "planned",
    SOWN: "sown",
    TRANSPLANTED: "transplanted",
    HARVESTABLE: "harvestable",
    HARVESTED: "harvested"
}

export const PLANTING_STATUS_LABELS = {
    [PLANTING_STATUS.PLANNED]: "Gepland",
    [PLANTING_STATUS.SOWN]: "Gezaaid",
    [PLANTING_STATUS.TRANSPLANTED]: "Verplant",
    [PLANTING_STATUS.HARVESTED]: "Geoogst"
}

export const GENERIC_ERROR_MESSAGE = "Sorry, er ging iets fout. Controleer wat je hebt ingevulden probeer het (later) opnieuw."