import React, {useEffect, useRef, useState} from 'react';
import './style.css';
import api from "../../api";
import {useParams} from "react-router-dom";

const GardenMap = () => {
    const [bedPositions, setBedPositions] = useState([]);
    const zoom = 1;
    const snapToGrid = 50
    const containerRef = useRef(null);

    const {id} = useParams();
    useEffect(() => {
        api.garden(id).then((garden) => {
            setBedPositions(garden.beds.map(((bed, i) => ({
                ...bed,
                left: i * 50,
                top: 0,
                rotation: 0
            }))));
        })
    }, []);

      const handleMouseDown = (index, e) => {
        if (e.shiftKey) {
            startRotating(index, e);
        } else {
            startDragging(index, e);
        }
    };

    const startDragging = (index, e) => {
        const containerRect = containerRef.current.getBoundingClientRect();
        const startX = (e.clientX - containerRect.left - bedPositions[index].left * zoom) / zoom;
        const startY = (e.clientY - containerRect.top - bedPositions[index].top * zoom) / zoom;

        const onMouseMove = (e) => {
            const updatedBeds = [...bedPositions];
            let newLeft = (e.clientX - containerRect.left) / zoom - startX;
            let newTop = (e.clientY - containerRect.top) / zoom - startY;

            if (snapToGrid > 0) {
                newLeft = Math.round(newLeft / snapToGrid) * snapToGrid;
                newTop = Math.round(newTop / snapToGrid) * snapToGrid;
            }

            updatedBeds[index].left = newLeft;
            updatedBeds[index].top = newTop;
            setBedPositions(updatedBeds);
        };

        const onMouseUp = () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    };

    const startRotating = (index, e) => {
        const rect = e.target.getBoundingClientRect();
        const initialX = rect.left + rect.width / 2;
        const initialY = rect.top + rect.height / 2;
        const initialAngle = getRotationAngle(bedPositions[index]);

        const onMouseMove = (e) => {
            const dx = e.clientX - initialX;
            const dy = e.clientY - initialY;
            const angle = Math.atan2(dy, dx) * (180 / Math.PI);
            const updatedBeds = [...bedPositions];
            updatedBeds[index].rotation = initialAngle + angle;
            setBedPositions(updatedBeds);
        };

        const onMouseUp = () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    };

    const getRotationAngle = (bed) => {
        return bed.rotation || 0;
    };


    return (
        <div ref={containerRef} className="bed-container">
            {bedPositions.map((bed, index) => (
                <div
                    key={bed.name}
                    className="bed"
                    style={{
                        width: `${bed.width * zoom}px`,
                        height: `${bed.length * zoom}px`,
                        top: `${bed.top * zoom}px`,
                        left: `${bed.left * zoom}px`,
                        transform: `rotate(${getRotationAngle(bed)}deg)`,

                    }}
                    onMouseDown={(e) => handleMouseDown(index, e)}
                >
                    {bed.name}

                </div>
            ))}
        </div>
    );
};

export default GardenMap;