import React, {useEffect, useState} from 'react'
import api from "../../api";
import {Button, Col, Row} from "antd";
import dayjs from "dayjs";
import {ArrowRightOutlined} from "@ant-design/icons";
import './style.css'


export default function Account() {

    const [plan, setPlan] = useState(false)
    const [company, setCompany] = useState(false)
    const [plans, setPlans] = useState(false)

    useEffect(() => {
        api.get_plan().then(setPlan)
        api.getAllPlans().then(setPlans)
        api.get_my_company().then(setCompany)
    }, []);


    return (
        <div>
            <h1>Mijn account</h1>

            {plan && company && (
                <Row>
                    <Col xs={24} md={12}>
                        <h2>Jouw Pakket</h2>
                        <p>{plan.name}</p>
                        <p>€ {plan.price.toFixed(2)} per jaar</p>


                    </Col>
                </Row>
            )}
            {plans && (
                <Row gutter={[90, 24]}>
                    {plans.map(p => (
                        <Col md={8} xs={24} key={`plan${p.id}`}>
                            <div className={(p.id === plan.id) ? "plan-box active-plan" : "plan-box"}>
                                <h2>{p.name}</h2>
                                {p.price > 0 && (
                                    <h3>€ {p.price} voor 1 jaar</h3>
                                )}
                                {p.price === 0 && (
                                    <h3>Voor altijd gratis!</h3>
                                )}
                                {p.price === -1 && (
                                    <h3>Vraag offerte aan</h3>
                                )}
                                <p>Inclusief 21% BTW</p>

                                <ul className={"plan-features"}>
                                    <li>Onbeperkt aantal tuinen</li>
                                    <li> Onbeperkt aantal bedden per tuin</li>

                                    {(p.crop_limit && p.crop_limit > 0) ? (
                                        <li>
                                            <b>Maximaal {p.crop_limit}</b> gewassen
                                        </li>
                                    ) : (
                                        <li>
                                            <b>Onbeperkt aantal</b> gewassen
                                        </li>
                                    )}
                                    <li>Rij en plantafstand calculator</li>
                                    <li>Zaai, verplant en oogsttijden calculator</li>
                                    {(p.has_plukkers) && (
                                        <li>Koppeling met Plukkers.com</li>
                                    )}
                                    {(p.has_bolster) && (
                                        <li>Koppeling met Bolster.nl</li>
                                    )}

                                    {(p.has_team) && (
                                        <li>Manage je teamleden en hun taken</li>
                                    )}
                                </ul>

                                {(p.id === plan.id) && (
                                    <>
                                        <Button size={"large"} disabled>Je huidige pakket</Button>
                                        {(company.plan_expires_at && (
                                            <p className={"mar-t-1"}>
                                                Je account verloopt op:<br/>
                                                {dayjs(company.plan_expires_at).format("dddd D MMMM YYYY")}
                                            </p>
                                        ))}
                                        {(plan.price > 0 && (
                                                <Button type={"primary"} onClick={() => {
                                                    api.upgradePlan(p.id).then(({url}) => {
                                                        document.location = url
                                                    })
                                                }}>Met 1 jaar verlengen</Button>)
                                        )}
                                    </>
                                )}
                                {(p.id !== plan.id && p.requires_contact) && (
                                    <Button size={"large"} type={"dashed"} onClick={() => {
                                        document.location = ''
                                    }}>Plan een gesprek</Button>
                                )}
                                {(p.id !== plan.id && !p.requires_contact && p.price > plan.price) && (
                                    <>
                                        <Button type={"primary"} size={"large"} onClick={() => {
                                            api.upgradePlan(p.id).then(({url}) => {
                                                document.location = url
                                            })
                                        }}>Selecteer pakket <ArrowRightOutlined/></Button>

                                        <p className={"text-center mar-t-1"}>Niet goed? Geld terug!</p>
                                    </>
                                )}

                            </div>
                        </Col>
                    ))}
                </Row>
            )}
        </div>
    )
}