import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import api from "../../api";
import {Button, Col, Form, Input, Modal, Row, Table} from "antd";

export default function AdminIndex(){
    const [users, setUsers] = useState([]);
    const [blogPosts, setBlogPosts] = useState([]);
    const [blogPostIdeads, setBlogPostIdeads] = useState([]);
    const [addBlogPostModalOpen, setAddBlogPostModalOpen] = useState(false);
    const [generateIdeasModalOpen, setGenerateIdeasModalOpen] = useState(false);
    const [generateIdeasForm, setGenerateIdeasForm] = Form.useForm();
    const [addBlogPostForm, setAddBlogPostForm] = Form.useForm();

    useEffect(() => {
        api.get_all_users().then((users) => {
            setUsers(users)
        })
    }, []);

    useEffect(() => {
        api.get_all_blog_posts().then((posts) => {
            setBlogPosts(posts)
        })
    }, []);

    const generateBlogPostIdeas = () => {
        api.generate_blog_post_ideas(generateIdeasForm.getFieldValue('prompt')).then((ideas) => {
            setBlogPostIdeads(ideas)
        })
    };

    const clickIdea = (idea) => {
        addBlogPostForm.setFieldsValue({
            prompt: idea
        })
        setAddBlogPostModalOpen(true)
    }

    const clickAddBlogPost = () => {
        addBlogPostForm.validateFields().then((values) => {
            api.add_blog_post(values).then(() => {
                setAddBlogPostModalOpen(false)
                api.get_all_blog_posts().then((posts) => {
                    setBlogPosts(posts)
                })
            })
        })
    }

    return (
        <>
            <Modal open={generateIdeasModalOpen} onCancel={() => setGenerateIdeasModalOpen(false)}
                   onOk={() => generateBlogPostIdeas()}>
                <Form form={generateIdeasForm} layout={"vertical"}>
                    <Form.Item name={"prompt"} label={"Geef sugggesties over"}>
                        <Input/>
                    </Form.Item>
                </Form>


                <ul>
                    {blogPostIdeads.map((idea) => (
                        <li key={idea}>
                            <Button onClick={() => clickIdea(idea)} type={"link"}>{idea}</Button>
                        </li>
                    ))}
                </ul>

            </Modal>
            <Modal open={addBlogPostModalOpen} onCancel={() => setAddBlogPostModalOpen(false)}
                   onOk={() => clickAddBlogPost()}>
                <Form form={addBlogPostForm} layout={"vertical"}>
                    <Form.Item name={"prompt"} label={"Schrijf een blog artikel over"}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={"meta_keywords"}  label={"Keywords"}>
                        <Input/>
                    </Form.Item>
                </Form>
            </Modal>
            <h1>Admin</h1>
            <Row>
                <h2>Users</h2>
                <Table style={{width: '100%'}} dataSource={users} columns={[
                    {title: 'ID', dataIndex: 'id', key: 'id'},
                    {title: 'Name', dataIndex: 'name', key: 'name'},
                    {title: 'Email', dataIndex: 'email', key: 'email'},
                    {title: 'Actions', key: 'actions', render: (user) => (
                        <Link to={`/admin/impersonate/${user.id}`}>Impersonate</Link>
                    )},
                ]} pagination={false}></Table>
            </Row>
            <Row>
                <Col span={24}>
                    <h1>Blog posts</h1>
                    <Button onClick={() => setAddBlogPostModalOpen(true)} type={"primary"}>Add Blog Post</Button>
                    <Button onClick={() => setGenerateIdeasModalOpen(true)} type={"default"}>Generate Ideas</Button>

                    <Table dataSource={blogPosts} columns={[
                            {title: 'Generated', dataIndex: 'is_generated', key: 'is_generated', render: (value) => value ? 'Yes' : 'No'},
                            {title: 'Prompt', dataIndex: 'prompt', key: 'prompt'},
                            {title: 'Meta Keywords', dataIndex: 'meta_keywords', key: 'meta_keywords'},
                            {title: 'View', dataIndex: 'slug', key: 'slug', render: (id) => <Link target={"_blank"} to={api.get_blog_url(id)}>View</Link>},
                        ]} />
                </Col>
            </Row>
        </>
    )
}