import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import api from "../../api";
import {Spin} from "antd";

export default function Index() {

    const navigate = useNavigate();
    useEffect(() => {
        if (api.isLoggedIn()) {
            navigate("/gardens");
        } else {
            document.location = 'https://www.oogst.app/';
        }
    }, []);

    return (
        <div>
            <Spin></Spin>
        </div>
    )
}