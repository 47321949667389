import React, {useState} from 'react'
import {Alert, Button, Col, Form, Input, Modal, Row, Spin} from "antd";
import {PlusCircleFilled} from "@ant-design/icons";
import api from "../../api";

import debounce from 'lodash/debounce';


export default function SearchCropModal({addCropModalOpen, onCancel, onCropFound, onAddManually}) {

    const [addCropForm] = Form.useForm();
    const [cropInfoLoading, setCropInfoLoading] = useState(false)
    const [searchResults, setSearchResults] = useState([])
    const [searching, setSearching] = useState(false)
    const [didSearch, setDidSearch] = useState(false)

    function handleSearch(e) {
        setSearchResults([])
        setSearching(true)
        setDidSearch(true)
        api.search(addCropForm.getFieldValue('query')).then(setSearchResults).finally(() => setSearching(false))
    }

    function getCropInfo(name, variety, url, supplier) {
        setCropInfoLoading(true)
        api.getCropInfo({name, variety, url, supplier}).then((response) => {
            onCropFound(response)
        })
    }

    return (
        <Modal open={addCropModalOpen}
               title={"Nieuw gewas toevoegen"} onCancel={onCancel}
               footer={null}>
            <Form form={addCropForm} layout="vertical">
                <Row>
                    <Col span={18}>
                        <Form.Item label="Zoek naar gewassen" name="query">
                            <Input disabled={cropInfoLoading} onChange={debounce(handleSearch, 300)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6} className={"text-right"}>
                        <Form.Item label={" "}>
                            <Button type={"primary"} onClick={handleSearch} loading={searching} disabled={searching}>Zoeken</Button>
                        </Form.Item>
                    </Col>
                </Row>

                {!searching ? null : (<>

                    <div className={"text-center"}>
                        <Spin/>
                        <div>Een moment geduld, we zoeken nu naar zaden bij Plukkers en De Bolster...</div>
                    </div>
                </>)}
                {!cropInfoLoading ? (
                    <>
                        {searchResults.map((res) => {
                            return (<Row key={res.name + res.variety} className={"search-result"}>
                                <Col span={4}>
                                    <img src={res.image} alt={res.name}/>
                                </Col>
                                <Col span={16}>
                                    <span>{res.name} {res.variety}</span>
                                    <br/>{res.supplier}
                                </Col>
                                <Col span={4}>
                                    {cropInfoLoading ? <Spin/> : null}
                                    <Button disabled={cropInfoLoading} type="link" size={"small"}
                                            onClick={() => getCropInfo(res.name, res.variety, res.url, res.supplier)}>
                                        <PlusCircleFilled/>
                                    </Button>
                                </Col>
                            </Row>)
                        })}

                        {!searchResults.length && !searching && didSearch && (
                            <Alert description={"Geen resultaten gevonden, probeer een andere zoekterm."}
                                   type={"info"}
                                   className={"text-center"}/>
                        )}

                        {!searching && (
                            <Button className={"mar-t-1"} disabled={cropInfoLoading} onClick={onAddManually}>
                                Zoeken overslaan, zelf toevoegen
                            </Button>
                        )}

                    </>
                ) : (
                    <div className={"text-center"}>
                        <Spin/>
                        <div>Een moment geduld, we halen alle gegevens op van het geselecteerde gewas...</div>
                    </div>
                )}
            </Form>
        </Modal>
    )
}