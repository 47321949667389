import React from 'react'
import {Button} from "antd";
import api from "../../api";

export default function PaymentRetry() {

    function retryPayment(){
        api.retryPayment().then(({url}) => {
            document.location = url
        })

    }

    return (
        <div>
            <h1>De betaling is mislukt :-( !</h1>
            <Button onClick={() => retryPayment()} size={"large"} type={"primary"}>Klik hier om het opnieuw te proberen</Button>
        </div>
    )
}