import {Button, Checkbox, Col, Form, Input, notification, Row} from "antd";
import React from "react";
import api from "../../api";
import './style.css';
import {ArrowRightOutlined} from "@ant-design/icons";
import {useSearchParams} from "react-router-dom";

export default function Register() {

    const [notificationApi, contextHolder] = notification.useNotification();
    const {plan_id} = useSearchParams();

    const onFinish = (values) => {

        if (!values.tos) {
            notificationApi.error({
                message: `Je moet akkoord gaan met de algemene voorwaarden en privacy policy!`,
                description: 'Lees de algemene voorwaarden en privacy policy en vink het vakje aan.',
                placement: 'top',
                duration: 3
            });
            return
        }

        api.register(values.email, values.name, values.password, values.company, values.tos, plan_id).then((response) => {
            if (response.token) {
                localStorage.setItem('token', response.token);
                window.location.href = response.url;
            }
        }).catch((error) => {
            if (error.response.data.detail && error.response.data.detail == "UserAlreadyExists") {
                notificationApi.error({
                    message: `Dit emailadres is al in gebruik!`,
                    description: 'Probeer in te loggen met dit emailadres of gebruik een ander emailadres.',
                    placement: 'top',
                    duration: 3
                });
                return
            }

            notificationApi.error({
                message: `Woops! Er is iets misgegaan! 🥕`,
                description: 'Check of je alles goed hebt ingevuld en probeer het later nog eens. Lukt het nog steeds niet? Neem contact op met ons.',
                placement: 'top',
                duration: 3
            });
        })
    }

    return (
        <>
            <Form
                name="register_form"
                className="register-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}>
                <Row justify="center">
                    {contextHolder}
                    <Col md={6} xs={24}>
                        <h1>Registreer vandaag nog!</h1>
                        <p>Begin vandaag nog met het plannen van jouw moestuin.</p>

                        <h2>Over jou</h2>
                        <Form.Item
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vul je voor en achternaam in',
                                },
                            ]}
                        >
                            <Input placeholder="Voor- en achternaam"/>
                        </Form.Item>
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vul een emailadres in',
                                },
                                {
                                    type: 'email',
                                    message: 'Vul een geldig emailadres in',
                                },
                            ]}
                        >
                            <Input placeholder="E-mail address"/>
                        </Form.Item>
                        <h2>Voor je account</h2>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Voer een wachtwoord in!',
                                },
                            ]}
                        >
                            <Input.Password placeholder="Wachtwoord"/>
                        </Form.Item>
                        <Form.Item
                            name="password_confirm"
                            rules={[
                                {
                                    required: true,
                                    message: 'Voer een wachtwoord in!',
                                },
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('De wachtwoorden die je hebt ingevuld komen niet overeen!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password placeholder="Herhaal wachtwoord"/>
                        </Form.Item>
                        <h2>Over jouw tuin</h2>
                        <Form.Item
                            name="company"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vul de naam van je tuin in, bijvoorbeeld: "Stef\'s moestuin" of "Tuinderij de brocoli"',
                                },
                            ]}>
                            <Input placeholder="Naam van je tuin(derij)"/>
                        </Form.Item>

                        <Form.Item name={"tos"}  valuePropName="checked" rules={[{ required: true, message: 'Je moet akkoord gaan met de algemene voorwaarden en privacy policy!' }]}>
                            <Checkbox>
                                Ik ga akkoord met de <a href="https://www.oogst.app/tos" target={"_blank"}>algemene voorwaarden</a> en <a target={"_blank"} href="https://www.oogst.app/privacy-policy">privacy
                                policy</a>.</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} className={"text-center"}>
                        <Form.Item>
                            <Button type="primary" size={"large"} htmlType="submit" className="login-form-button">
                                Gratis aanmelden <ArrowRightOutlined/>
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    );
}