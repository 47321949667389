import {Button, Col, Form, Input, message, notification, Row} from "antd";
import React from "react";
import api from "../../api";
import {useNavigate, useParams} from "react-router-dom";

export default function ResetPassword() {

    const [notificationApi, contextHolder] = notification.useNotification();
    const {user_id, token} = useParams();
    const navigate = useNavigate();

    const onFinish = (values) => {

        api.resetPassword(user_id, token, values.password).then((response) => {
            navigate('/login?password_reset=1')
        }).catch(() => {
            notificationApi.error({
                message: `Sorry, er ging iets mis!`,
                description: 'We konden je wachtwoord niet resetten. Probeer een nieuwe link aan te vragen.',
                placement: 'top',
                duration: 5
            })
        })
    }

    return (
        <>
            <Form
                name="register_form"
                className="register-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}>
                <Row justify="center">
                    {contextHolder}
                    <Col md={6} xs={24}>
                        <h1>Wachtwoord resetten</h1>
                        <p>Vul hieronder je nieuwe wachtwoord in</p>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Voer een wachtwoord in!',
                                },
                            ]}
                        >
                            <Input.Password placeholder="Wachtwoord"/>
                        </Form.Item>
                        <Form.Item
                            name="password_confirm"
                            rules={[
                                {
                                    required: true,
                                    message: 'Voer een wachtwoord in!',
                                },
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('De wachtwoorden die je hebt ingevuld komen niet overeen!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password placeholder="Herhaal wachtwoord"/>
                        </Form.Item>

                    </Col>
                </Row>
                <Row>
                    <Col span={24} className={"text-center"}>
                        <Form.Item>
                            <Button type="primary" size={"large"} htmlType="submit" className="login-form-button">
                                Wachtwoord resetten
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    );
}