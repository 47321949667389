// export function calculateMaxPlantsInBed(length, width, plantRowDistance, plantDistanceInRow) {
//   // Calculate the number of plants based on rows along the length
//   let rowsLength = Math.floor(length / plantRowDistance);
//   let plantsInRowLength = Math.floor(width / plantDistanceInRow);
//   let totalPlantsLength = rowsLength * plantsInRowLength;
//
//   // Calculate the number of plants based on rows along the width
//   let rowsWidth = Math.floor(width / plantRowDistance);
//   let plantsInRowWidth = Math.floor(length / plantDistanceInRow);
//   let totalPlantsWidth = rowsWidth * plantsInRowWidth;
//
//   // Return the maximum number of plants
//   return Math.max(totalPlantsLength, totalPlantsWidth);
// }
//

import dayjs from "dayjs";

export function calculateMaxPlantsInBed(length, width, plantRowDistance, plantDistanceInRow) {
    // Number of rows we can have in the bed
    const rows = Math.floor(length / plantRowDistance);

    // Number of plants we can have in a row
    const plantsInRow = Math.floor(width / plantDistanceInRow);

    // Total number of plants
    const totalPlants = rows * plantsInRow;

    return totalPlants;
}


export function getFirstSowableDate(monthNumber) {
    const today = dayjs();
    const currentMonth = today.month() + 1; // month() returns 0-11, so add 1 for 1-12 range
    let targetYear = today.year();

    if (monthNumber < currentMonth) {
        targetYear += 1; // Move to the next year if the month has passed this year
    }

    return dayjs().year(targetYear).month(monthNumber - 1).startOf('month'); // monthNumber - 1 as month() uses 0-11
}

export function calculateCropDates(crop, user_sow_at) {

    const plant_min = crop.plant_min ? crop.plant_min : crop.sow_min;


    const daysToTransplant = (plant_min - crop.sow_min);
    const daysToHarvest = (crop.harvest_min - plant_min);
    const sow_at = user_sow_at ? user_sow_at : getFirstSowableDate(crop.sow_min);

    return {
        sow_at: sow_at,
        transplant_at: sow_at.add(daysToTransplant, 'months'),
        harvest_at: sow_at.add(daysToTransplant + daysToHarvest, 'months')
    }
}

export function calculateRowsAndPlants(bed, crop) {

    const rows = Math.ceil(bed.width / crop.row_distance);
    const plants = Math.ceil(bed.length / crop.plant_distance);

    return {
        rows: rows,
        plants_per_row: plants
    }
}