import React from 'react'
import {Button, Col, Row} from "antd";
import CropTiming from "../CropTiming";

export default function CropInfo({crop}) {
    function getDomainName(url) {
        const hostname = new URL(url).hostname;
        const domain = hostname.replace(/^www\./, ''); // Remove 'www.' if present
        return domain;
    }

    return (
        <>
            <Row gutter={[18, 18]}>
                <Col md={2} xs={12}>
                    {crop.image && (
                        <img src={crop.image} style={{maxWidth: '100%', borderRadius: '15px'}} alt={crop.name}/>
                    )}
                </Col>
                <Col md={8} xs={12}>
                    <Row>
                        <Col span={12}>Rijafstand</Col>
                        <Col>{crop.row_distance}cm</Col>
                    </Row>
                    <Row>
                        <Col span={12}>Plantafstand</Col>
                        <Col>{crop.plant_distance}cm</Col>
                    </Row>

                    <CropTiming crop={crop}/>
                </Col>
                <Col xs={24} md={4}>
                    {(crop.url) && (
                        <Button block type={"link"} href={crop.url} target={"_blank"}>
                            Open op {getDomainName(crop.url)}
                        </Button>
                    )}
                </Col>
            </Row>
        </>
    )
}