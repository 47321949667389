import {useLocation} from "react-router-dom";
import {Button, message, Modal} from "antd";
import {PlaySquareOutlined} from "@ant-design/icons";
import "./style.css"
import {useState} from "react";

function HeaderHelpLink() {

    const location = useLocation();
    const [modalOpen, setModalOpen] = useState(false);
    const [videoSource, setVideoSource] = useState(null);

    const videos = [
        {
            pathname: "/payment/success",
            loom: "https://www.loom.com/embed/aa3f9270ed7c4805b01c2245040ccb9b?sid=a0d5b6cd-5685-4441-891a-9dee29e5c6d1"
        },
        {
            pathname: "/welcome",
            loom: "https://www.loom.com/embed/aa3f9270ed7c4805b01c2245040ccb9b?sid=a0d5b6cd-5685-4441-891a-9dee29e5c6d1"
        },
        {
            pathname: "/gardens",
            loom: "https://www.loom.com/embed/12184292514647fcbf5eb31f416357f9?sid=1440f040-4f74-4ee1-82bb-3d2d9e4188d8"
        },
        {
            pathname: "/planner",
            loom: "https://www.loom.com/embed/12184292514647fcbf5eb31f416357f9?sid=1440f040-4f74-4ee1-82bb-3d2d9e4188d8"
        }
    ]



    function showVideo(){
        const video = videos.filter((video) => {
            return location.pathname.startsWith(video.pathname)
        })

        if(video.length === 0) {
            message.info("Sorry, we hebben voor deze pagina nog geen video opgenomen. ")
            return
        }

        setModalOpen(true)
        setVideoSource(video[0]?.loom)
    }

    const video = videos.filter((video) => {
        return location.pathname.startsWith(video.pathname)
    })

    if(video.length === 0) {
        return null
    }

    return (
        <>
            <Modal open={modalOpen} width={"90%"} destroyOnClose={true} onCancel={() => setModalOpen(false)} footer={(
                <Button onClick={() => setModalOpen(false)}>Sluiten</Button>
            )}>

                <div className={"help-loom-wrap"}>
                    {(videoSource && (
                        <iframe
                            title={"Demo"}
                            src={videoSource}
                            frameBorder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen
                        ></iframe>
                    ))}
                </div>
            </Modal>
            <div className={"header-help-link-wrap"}>
                <Button onClick={() => showVideo()}>
                    <PlaySquareOutlined/> Hoe werkt dit?
                </Button>
            </div>
        </>
    )
}

export default HeaderHelpLink