import {Button, Form, Input, Modal} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import api from "../../api";

export default function LogUpdateModal({planting, open, onCancel}) {

    const [form] = Form.useForm()
    const [uploadedFile, setUploadedFile] = React.useState(null);
    function logUpdate() {
        form.validateFields().then(values => {
            const update = {
                title: values.title,
                description: values.description,
                image: uploadedFile,
                send_update: values.send_update
            }
            api.logUpdate(planting.id, update).then(() => {
                onCancel()
                setUploadedFile(null);
                form.resetFields();
            })
        })
    }

    function handleUpload(event) {
        event.preventDefault();
        api.uploadToCdn(event.target.files[0]).then(setUploadedFile);
    }

    return (

        <Modal open={open} onCancel={onCancel} footer={(
            <Button type={"primary"} onClick={logUpdate} disabled={uploadedFile === null}>Log update</Button>
        )}>
            <h1>Update</h1>
            <Form form={form} labelCol={24} layout={"vertical"}>
                <Form.Item name="title" label="Titel van update" labelAlign="left">
                    <Input style={{width: "100%"}}/>
                </Form.Item>
                <Form.Item name="description" label={"Kleine beschrijving"}>
                    <TextArea style={{width: "100%"}}/>
                </Form.Item>
                <Form.Item label={"Image"}>
                    {uploadedFile && <img src={uploadedFile} style={{width: "50%"}} alt={uploadedFile}/>}
                    <input type="file" onChange={handleUpload}/>
                </Form.Item>
            </Form>
        </Modal>
    )
}