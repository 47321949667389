import React, {useEffect, useState} from 'react';
import {Button, Col, Layout, Menu, Row, theme} from 'antd';
import {useNavigate, useRoutes} from "react-router-dom";
import routes from "../../routes";
import './style.css';
import {
    CloseOutlined,
    ContactsFilled,
    HomeFilled,
    LogoutOutlined, MenuOutlined,
    SoundFilled, StopFilled,
    TeamOutlined, UsergroupAddOutlined,
    UserOutlined
} from "@ant-design/icons";
import {CropIcon} from "../../Icons";
import api from "../../api";
import dayjs from "dayjs";
import HeaderHelpLink from "../HeaderHelpLink";

const {Header, Content, Footer, Sider} = Layout;

const AppLayout = () => {

    const navigate = useNavigate();
    const routingElement = useRoutes(routes)
    const [plan, setPlan] = useState({})
    const [company, setCompany] = useState({})

    let isExpired = false

    const goToPage = (e) => {
        navigate(e.key)
    }

    useEffect(() => {
        if(api.isLoggedIn()){
            api.get_plan().then(setPlan)
            api.get_my_company().then(setCompany)
        }
    }, []);

    let menuItems = [
        {
            key: 'gardens',
            label: 'Tuinen',
            onClick: goToPage,
            icon: <HomeFilled/>
        },
        {
            key: 'crops',
            label: 'Gewassen',
            onClick: goToPage,
            icon: <CropIcon/>
        }
    ]

    if(plan.has_updates) {
        menuItems.push({
            key: 'updates',
            label: 'Updates',
            onClick: goToPage,
            icon: <SoundFilled/>
        })
    }

    if(plan.has_reservations) {
        menuItems.push({
            key: 'reservations',
            label: 'Aanvragen',
            onClick: goToPage,
            icon: <ContactsFilled/>
        })
    }

    if(plan.has_team) {
        menuItems.push({
            key: 'team',
            label: 'Mijn Team',
            onClick: goToPage,
            icon: <TeamOutlined/>
        })
    }

    if(company.plan_expires_at) {
        const dateToCheck = dayjs(company.plan_expires_at); // Example date
        const now = dayjs(); // Current date and time

        if (dateToCheck.isBefore(now)) {
            menuItems = []
            isExpired = true
        }
    }



    menuItems.push({
        key: 'account',
        label: 'Mijn account',
        onClick: goToPage,
        icon: <UserOutlined/>
    })

    if(api.isAdmin()){
        menuItems.push({
            key: 'admin',
            label: 'Admin',
            onClick: goToPage,
            icon: <UsergroupAddOutlined/>
        })
    }

    if (localStorage.getItem('admin_token')) {
        menuItems.push({
            key: 'admin/impersonate/stop',
            label: 'Back to admin',
            onClick: goToPage,
            icon: <StopFilled/>
        })
    }


    menuItems.push({
        key: 'logout',
        label: 'Uitloggen',
        onClick: goToPage,
        icon: <LogoutOutlined/>
    })

    const menuOpenLocal = localStorage.getItem('menuOpen')

    const {useToken} = theme;
    const [collapsed, setCollapsed] = useState(menuOpenLocal === "true");
    const {token} = useToken();
    const isLoggedIn = api.isLoggedIn()

    function handleOnCollapse(value) {
        localStorage.setItem('menuOpen', value)
        setCollapsed(value)
    }

    return (
        <Layout
            style={{
                minHeight: '100vh',
            }}
        >
            {(isLoggedIn && (
                <Sider defaultCollapsed={true}
                       className={"custom-slider"}
                       collapsedWidth={"40px"}
                       collapsible
                       collapsed={collapsed}
                       onCollapse={(value) => handleOnCollapse(value)}
                       trigger={null}
                >
                    <div style={{textAlign: 'right', paddingTop: '8px'}}>
                    <Button type={"link"} onClick={() => handleOnCollapse(!collapsed)}>
                        {collapsed ? (<MenuOutlined />) : <CloseOutlined/> }
                    </Button>
                    </div>
                    <Menu defaultSelectedKeys={['1']} mode="inline" items={menuItems}/>
                </Sider>
            ))}
            <Layout>

                <Header
                    style={{
                        padding: 0,
                        background: 'white',
                    }}
                >
                    <Row>
                        <Col span={22}>
                            <img style={{maxHeight: '40px', marginLeft: '28px'}} src={"/static/img/logo.png"}/>
                        </Col>
                        <Col span={2}><HeaderHelpLink /></Col>
                    </Row>

                </Header>

                <Content>
                    {isExpired && (
                        <div style={{padding: '20px', textAlign: 'center', background: 'white'}}>
                            <h1>Je abonnement is verlopen of niet actief!</h1>
                            <p>Je kunt nu geen gebruik meer maken van Oogst.app, doe eerst een betaling om verder te gaan.</p>
                            <Button onClick={() => {
                                api.retryPayment().then(({url}) => {
                                    document.location = url
                                })
                            }} type={"primary"}>Verleng je abonnement</Button>
                        </div>
                    )}
                    <div
                        style={{
                            padding: 24,
                            minHeight: 360,
                            background: token.colorBgContainer,
                        }}
                    >
                        {routingElement}
                    </div>
                </Content>
                <Footer
                    style={{
                        textAlign: 'center',
                    }}
                >
                    Oogst ©{new Date().getFullYear()}
                </Footer>
            </Layout>
        </Layout>
    );
};
export default AppLayout;