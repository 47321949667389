import React, {useEffect, useState} from 'react'
import {Alert, Button, Col, Form, Input, message, Modal, Row, Table} from "antd";
import {useForm} from "antd/es/form/Form";
import api from "../../api";

const Team = () => {

    const [inviteModalOpen, setInviteModalOpen] = useState(false)
    const [teamMembers, setTeamMembers] = useState([])
    const [addTeamMemberForm] = useForm()

    useEffect(() => {
        // Fetch team members
        api.get_team_members().then(setTeamMembers)
    }, []);

    const cancelInvite = () => {
        setInviteModalOpen(false)
        addTeamMemberForm.resetFields()
    }

    const inviteTeamMember = () => {
        addTeamMemberForm.validateFields().then(values => {

            api.add_team_member(values.name, values.email).then((response) => {
                setInviteModalOpen(false)
                addTeamMemberForm.resetFields()
                api.getTeamMembers().then(setTeamMembers)
                message.success('Je teamlid is uitgenodigd! We hebben ze een email gestuurd :-)')
            }).catch((error) => {
              message.error('Er is iets misgegaan bij het uitnodigen van je teamlid. Probeer het later nog eens.')
            })
        })
    }

    return (
        <div>
            <h1>Mijn Team</h1>
            <Modal open={inviteModalOpen}
                   title={"Nieuw teamlid uitnodigen"}
                   onCancel={cancelInvite}
                   onOk={inviteTeamMember}
                   okText={"Uitnodigen"}
                   cancelText={"Annuleren"}
            >
                <Form form={addTeamMemberForm} layout={"vertical"}>
                    <p>Het uitnodigen van een (nieuw) teamlid werkt als volgt:</p>
                    <ol>
                        <li>
                        Vul hieronder de gegevens van het nieuwe teamlid dat je wilt uitnodigen.
                            </li>
                        <li>Na het toevoegen zullen wij een uitnodiging sturen naar het opgegeven e-mailadres.</li>
                        <li>Vervolgens kan het nieuwe teamlid deze accepteren en inloggen.</li>
                    </ol>
                    <Form.Item label={"Naam"} name={"name"}
                        rules={[
                            {
                                required: true,
                                message: 'Vul de naam in van je teamlid',
                            },
                        ]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={"E-mailadres"} name={"email"} rules={[
                            {
                                required: true,
                                message: 'Vul een (geldig) emailadres in',
                            },
                            {
                                type: 'email',
                                message: 'Vul een (geldig) emailadres in',
                            },
                        ]}>
                        <Input type={"email"}/>
                    </Form.Item>
                </Form>
            </Modal>
            <Row>
                <Col span={24}>
                    <Button type={"primary"} onClick={() => setInviteModalOpen(true)} className={"mar-b-1"}>Teamlid uitnodigen</Button>
                    <Table columns={[{
                        title: 'Naam',
                        dataIndex: 'name',
                        key: 'name',
                    }, {
                        title: 'E-mailadres',
                        dataIndex: 'email',
                        key: 'email',
                    }]} dataSource={teamMembers}></Table>
                </Col>
            </Row>
        </div>
    )
}


export default Team