import React from 'react'
import {Col, Row} from "antd";

export default function CropTiming({crop}) {

    const months = [
        '-',
        'Jan',
        'Feb',
        'Mrt',
        'Apr',
        'Mei',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ]

    return (
        <>
            {(crop.sow_min !== 0 && crop.sow_max !== 0) && (
                <Row>
                    <Col span={12}>Voorzaaien</Col>
                    <Col>{months[crop.sow_min]} t/m {months[crop.sow_max]}</Col>
                </Row>
            )}
            {(crop.plant_min !== 0 && crop.plant_max !== 0) && (
                <Row>
                    <Col span={12}>Planten</Col>
                    <Col>{months[crop.plant_min]} t/m {months[crop.plant_max]}</Col>
                </Row>
            )}
            {(crop.harvest_min !== 0 && crop.harvest_max !== 0) && (
                <Row>
                    <Col span={12}>Oogsten</Col>
                    <Col>{months[crop.harvest_min]} t/m {months[crop.harvest_max]}</Col>
                </Row>
            )}
        </>
    )
}