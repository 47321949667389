import {Button, Col, Form, Input, message, notification, Row} from "antd";
import React from "react";
import api from "../../api";

export default function ForgotPassword() {

    const [notificationApi, contextHolder] = notification.useNotification();

    const onFinish = (values) => {

        api.requestPasswordReset(values.email).then((response) => {

        }).finally(() => {
            notificationApi.info({
                message: `Wachtwoord resetten`,
                description: 'We hebben je een email gestuurd met een link om je wachtwoord opnieuw in te stellen.',
                placement: 'top',
                duration: 3
            })
        })
    }

    return (
        <>
            <Form
                name="register_form"
                className="register-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}>
                <Row justify="center">
                    {contextHolder}
                    <Col md={6} xs={24}>
                        <h1>Wachtwoord resetten</h1>
                        <p>Vul hieronder je emailadres in, dan ontvang je een email met daarin een link om je wachtwoord opnieuw in te stellen.</p>
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vul een emailadres in',
                                },
                                {
                                    type: 'email',
                                    message: 'Vul een geldig emailadres in',
                                },
                            ]}
                        >
                            <Input placeholder="E-mail address"/>
                        </Form.Item>

                    </Col>
                </Row>
                <Row>
                    <Col span={24} className={"text-center"}>
                        <Form.Item>
                            <Button type="primary" size={"large"} htmlType="submit" className="login-form-button">
                                Wachtwoord resetten
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    );
}