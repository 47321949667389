import LogUpdateModal from "../LogUpdateModal";
import {Button, Col, Image, Row} from "antd";
import React, {useEffect, useState} from "react";
import api from "../../api";
import dayjs from "dayjs";

export default function PlantingUpdates({planting}) {

    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [updates, setUpdates] = useState([])

    useEffect(() => {
        api.getPlantingUpdates(planting.id).then(setUpdates)
    }, [planting]);

    const onCancel = () => {
        setOpenUpdateModal(false);
        api.getPlantingUpdates(planting.id).then(setUpdates)
    }

    return (
        <div>
            <LogUpdateModal planting={planting} onCancel={onCancel} open={openUpdateModal}></LogUpdateModal>
            <Button onClick={() => setOpenUpdateModal(true)}>Nieuwe upate posten</Button>

            <Row gutter={12}>
                {updates.map(update => (
                    <Col key={update.id} md={6} xs={12}>
                        <div className={"update-box"}>
                            {api.isLoggedIn() && (
                                <h4>
                                    {update.sent && "VERZONDEN"}
                                    {!update.sent && "NIET VERZONDEN"}
                                </h4>
                            )}
                            <p><b>{dayjs(update.date).format("dddd D MMMM")}</b></p>
                            <div className={"image-box"}>
                                <Image src={update.image} width={"100%"}></Image>
                            </div>
                            <h1>{update.planting.crop.name}:<br/>{update.title}</h1>
                            <p>{update.description}</p>
                        </div>
                    </Col>
                ))}
            </Row>
        </div>
    )
}