import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, Modal, Row, Spin, message, InputNumber, Select, Alert} from "antd";
import api from "../../api";
import './style.css'
import CropTiming from "../../Components/CropTiming";
import {DeleteOutlined, EditOutlined, ExportOutlined} from "@ant-design/icons";
import SearchCropModal from "../../Components/SearchCropModal";
import {useNavigate} from "react-router-dom";

const {confirm} = Modal;

export default function Crops() {
    const [messageApi, contextHolder] = message.useMessage();
    const [crops, setCrops] = useState([])
    const [loading, setLoading] = useState(true)
    const [addCropModalOpen, setAddCropModalOpen] = useState(false)
    const [editCropModalOpen, setEditCropModalOpen] = useState(false)
    const [editingCrop, setEditingCrop] = useState(null);
    const [nameFilter, setNameFilter] = useState('')
    const [editCropForm] = Form.useForm();
    const [uploadedFile, setUploadedFile] = React.useState(null);
    const navigate = useNavigate();

    const spansDesktop = {
        img: 2,
        name: 8,
        actions: 12
    }

    const spansMobile = {
        img: 6,
        name: 18,
        actions: 24
    }

    function handleUpload(event) {
        event.preventDefault();
        api.uploadToCdn(event.target.files[0]).then(setUploadedFile);
    }

    function loadCrops() {
        setLoading(true)
        api.crops().then(setCrops).finally(() => setLoading(false))
    }

    useEffect(loadCrops, [])

    function changePlantingFilter(e) {
        setLoading(true)
        api.crops(e).then(setCrops).finally(() => setLoading(false))
    }

    if (loading) {
        return (
            <>
                {contextHolder}
                <Spin/>
            </>)
    }


    function handleCropClick(crop) {
        setUploadedFile(crop.image)
        editCropForm.setFieldsValue(crop)
        setEditingCrop(crop)
        setEditCropModalOpen(true)
    }

    function handleEditModalClose() {
        setEditCropModalOpen(false)
        setEditingCrop(null)
        editCropForm.resetFields()
    }

    function handleCropSave() {
        const values = editCropForm.getFieldsValue()
        if (editingCrop === null) {
            values.image = uploadedFile
            api.createCrop(values).then(() => {
                handleEditModalClose()
                message.success('Het nieuwe gewas is toegevoegd!')
                loadCrops()
            }).catch(error => {
                if (error.response.data.error === "CropLimitExceededException") {
                    Modal.error({
                        title: "Je hebt het maximum aantal gewassen bereikt",
                        okText: "Ga naar account instellingen",
                        onOk: () => navigate('/account'),
                        content: (
                            <div>
                                <p>Je hebt het maximum aantal gewassen bereikt voor je account.</p>
                                <p>Wil je meer gewassen toevoegen? Upgrade dan.</p>
                            </div>
                        )
                    })
                    return
                }

            })
        } else {
            values.image = uploadedFile
            api.updateCrop(editingCrop.id, values).then(() => {
                handleEditModalClose()
                message.success('De gegevens van het gewas zijn aangepast!')
            })
        }
    }

    function deleteCrop(crop) {

        confirm({
            title: 'Weet je zeker dat je dit gewas wilt verwijderen?',
            content: 'Dit kan niet ongedaan worden gemaakt!',
            onOk() {
                api.deleteCrop(crop.id).then((res) => {
                    if (res) {
                        messageApi.open({
                            type: 'success',
                            content: 'Het gewas is verwijderd',
                        });
                    } else {
                        messageApi.open({
                            type: 'error',
                            content: 'Kan het gewas niet verwijderen, het is nog in gebruik',
                        });
                    }
                    loadCrops()
                })
            },
            onCancel() {
            },
        });


    }

    const monthOptions = [
        {value: 1, label: 'Jan'},
        {value: 2, label: 'Feb'},
        {value: 3, label: 'Mrt'},
        {value: 4, label: 'Apr'},
        {value: 5, label: 'Mei'},
        {value: 6, label: 'Jun'},
        {value: 7, label: 'Jul'},
        {value: 8, label: 'Aug'},
        {value: 9, label: 'Sep'},
        {value: 10, label: 'Oct'},
        {value: 11, label: 'Nov'},
        {value: 12, label: 'Dec'},
        {value: 0, label: '-'},
    ]

    const cropList = crops.filter((crop) => {
        if (nameFilter === '') return true
        if (crop.name.toLowerCase().includes(nameFilter.toLowerCase())) {
            return true
        }
        if (crop.variety && crop.variety.toLowerCase().includes(nameFilter.toLowerCase())) {
            return true
        }
        return false
    })

    return (
        <>
            {contextHolder}
            <SearchCropModal
                addCropModalOpen={addCropModalOpen}
                onCancel={() => setAddCropModalOpen(false)}
                onCropFound={(crop) => {
                    setEditCropModalOpen(true)
                    editCropForm.setFieldsValue(crop)
                    setUploadedFile(crop.image)
                    setAddCropModalOpen(false)
                }}
                onAddManually={() => {
                    setEditCropModalOpen(true)
                    setAddCropModalOpen(false)
                }}
            />
            <Modal open={editCropModalOpen} onCancel={() => handleEditModalClose()} onOk={() => handleCropSave()}>
                <h1>Nieuw gewas toevoegen</h1>
                <Form form={editCropForm} layout="vertical">
                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item name="name" label="Gewas">
                                <Input placeholder={"Bijv. Rode Biet"}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="variety" label="Ras">
                                <Input placeholder={"Bijv. Kogel 2"}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item name="sow_min" label="Zaaien vanaf">
                                <Select options={monthOptions}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="sow_max" label="Zaaien tot">
                                <Select options={monthOptions}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item name="plant_min" label="Uitplanten vanaf">
                                <Select options={monthOptions}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="plant_max" label="Uitplanten tot">
                                <Select options={monthOptions}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item name="harvest_min" label="Oogsten vanaf">
                                <Select options={monthOptions}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="harvest_max" label="Oogsten tot">
                                <Select options={monthOptions}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item name="row_distance" label="Rij afstand">
                                <InputNumber addonAfter={"cm"}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="plant_distance" label="Plant afstand">
                                <InputNumber addonAfter={"cm"}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item name="url" label="URL / link">
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Afbeelding">
                                <input type="file" onChange={handleUpload}/>
                            </Form.Item>
                            {uploadedFile && <img src={uploadedFile} style={{maxWidth: "100%", maxHeight: '100px'}}
                                                  alt={uploadedFile}/>}
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Row gutter={[12, 12]}>
                <Col md={12} xs={24}>
                    <h1>Gewassen</h1>
                </Col>
                <Col md={12} xs={24} style={{textAlign: 'right'}}>
                    <Button type="primary" onClick={() => setAddCropModalOpen(true)}>Gewas toevoegen</Button>
                </Col>
            </Row>

            <Row gutter={[12,12]}>
                <Col md={6} xs={24}>
                    <Form.Item>
                        <Input placeholder={"Filteren lijst op naam"}
                               onChange={(e) => setNameFilter(e.target.value)}/>
                    </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                    <Form.Item>
                        <Select placeholder={"Filter lijst op zaaidatum"} onChange={changePlantingFilter}
                                options={monthOptions}/>
                    </Form.Item>
                </Col>
            </Row>


            {(cropList.length === 0 && nameFilter === '') && (
                <Alert
                    description={"Je hebt nog geen gewassen in je account staan. Klop op de knop `Gewas toevoegen` om ze direct toe tevoegen! :)"}>Geen
                    gewassen gevonden</Alert>
            )}
            {(cropList.length === 0 && nameFilter !== '') && (
                <Alert
                    description={"Geen van je gewassen voldoet aan de filter"}></Alert>
            )}

            {cropList.map((crop) => {
                return (
                    <Row gutter={[16, 16]} className={"crop-row"} key={`crop${crop.id}`}>
                        <Col xs={spansMobile.img} md={spansDesktop.img}>
                            {crop.image && (<img alt={crop.name} src={crop.image}/>)}
                        </Col>
                        <Col xs={spansMobile.name} md={spansDesktop.name}>
                            <h2>{crop.name} - {crop.variety}</h2>

                            <CropTiming crop={crop}/>
                            <br/>Plantafstand: {crop.plant_distance}x{crop.row_distance}cm
                        </Col>
                        <Col style={{textAlign: 'right'}} xs={spansMobile.actions} md={spansDesktop.actions}>
                            <Button onClick={() => handleCropClick(crop)} type="primary">
                                <EditOutlined/>
                            </Button>
                            <Button danger onClick={() => deleteCrop(crop)} className="mar-l-1">
                                <DeleteOutlined/>
                            </Button>

                            {crop.url && (
                                <Button className="mar-l-1" target="_blank" href={crop.url}>
                                    <ExportOutlined/> Naar website
                                </Button>
                            )}
                        </Col>
                    </Row>
                )
            })}
        </>
    )
}