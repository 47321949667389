import React from "react";
import {Button, Col, Form, Input, InputNumber, message, Modal, Row} from "antd";
import {useForm} from "antd/es/form/Form";
import api from "../../api";

const {confirm} = Modal;

export default function BedModal({garden, open, onCancel, bed}) {

    const [form] = useForm();

    if(bed) {
        form.setFieldValue('name', bed.name)
        form.setFieldValue('width', bed.width)
        form.setFieldValue('length', bed.length)
    }

    function deleteBed(){
        confirm({
            title: 'Weet je zeker dat je dit bed wilt verwijderen?',
            content: 'Dit kan niet ongedaan worden gemaakt!',
            onOk() {
                api.removeBed(garden.id, bed.id).then(() => {
                    message.success('Bed verwijderd! Sluit dit venster om terug te gaan naar de planner.')
                    onCancel()
                })
            },
            onCancel() {
            },
        });
    }

    function save() {
        if(bed) {
            api.updateBed(garden.id, bed.id, {
                name: form.getFieldValue('name'),
                width: form.getFieldValue('width'),
                length: form.getFieldValue('length')
            }).then(() => {
                message.success('Bed bijgewerkt! Let op! Als je de afmetingen hebt gewijzigd, kan dit gevolgen hebben voor de planten die al in het bed staan.')
                onCancel()
            })
            return
        }
        form.validateFields().then(values => {
            api.addBed(garden.id, {
                name: values.name,
                width: values.width,
                length: values.length,
            }).then(() => {
                message.success('Bed toegevoegd! Sluit dit venster om terug te gaan naar de planner of voeg direct nog een bed toe!')
                // onCancel()
            })
        })
    }

    return (
        <Modal
            open={open}
            onCancel={onCancel}
            footer={(
                <Row>
                    <Col span={12} className={"text-left"}>
                        {(bed && bed.id) && <Button danger onClick={() => deleteBed()}>Bed verwijderen</Button>}
                    </Col>
                    <Col span={12} className={"text-right"}>
                        <Button type={"primary"} onClick={save}>
                            {bed ? 'Bed aanpassen' : 'Bed toevoegen'}
                        </Button>
                    </Col>
                </Row>
            )}
        >
            <h1>
                {bed ? 'Bewerk bed' : 'Voeg een bed toe'}
            </h1>
            <Form form={form}
                labelCol={{span: 12}}>
                <Form.Item name="name" label="Naam van het bed" labelAlign="left">
                    <Input placeholder={"Voer een naam in"} style={{width: "100%"}}/>
                </Form.Item>
                <Form.Item name="width" label="Breedte van het bed (cm)" labelAlign="left">
                    <InputNumber placeholder={"Voer een breedte in"} style={{width: "100%"}}/>
                </Form.Item>
                <Form.Item name="length" label="Lengte van het bed (cm)" labelAlign="left">
                    <InputNumber placeholder={"Voer een lengte in"} style={{width: "100%"}}/>
                </Form.Item>
            </Form>
        </Modal>
    )
}